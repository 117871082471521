/* src/styles/VerifyEmail.css */
:root {
  --link-color: #008F11;
}

.verify-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  margin: 8rem auto;
  gap: 2rem;
}

.verify-email-logo {
  max-width: 320px;
}

.h1,
.h2,
.h3,
.h4 {
  font-weight: 600;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.25rem;
}

.h3 {
  font-size: 1.125rem;
}

.h4 {
  font-size: 1rem;
}

.success {
  color: green;
}

.error {
  color: red;
}

.button {
  background-color: var(--link-color);
  font-size: 1rem;
  padding: 0.4rem 1.5rem;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 2px var(--link-color) solid;
  color: white;
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 768px) {
  .verify-email-logo {
    max-width: 240px;
  }
}