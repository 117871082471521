/* //src/styles/index.css */
:root {
  --secondary-color: #fcb900;
  --background-color: black;
  --primary-font-color: white;
  --secondary-font-color: black;
  --laptop-container-padding: 1rem 8rem;
  --mobile-container-padding: 1rem 2rem;
  --primary-font: 'Figtree', sans-serif;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
  color: var(--primary-font-color);
  font-family: var(--primary-font);
}

body,
html {
  background-color: var(--background-color);
}

.main-container {
  background-color: var(--background-color);
  flex: 1;
  margin: 0 auto;
}

button {
  background-color: var(--secondary-color);
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  width:fit-content;
  margin: 0 auto;
  cursor: pointer;
}

button.icon {
  font-size: 2rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

a {
  color: var(--secondary-color);
  text-underline-offset: 0.25rem;
}

.icon-link path {
  fill: var(--secondary-color) !important;
  text-decoration: underline;
}

.icon-link {
  text-underline-offset: 0.25rem;
  text-decoration: underline;
}

.svg-inline--fa {
  font-size: x-large;
}

.visible-on-laptop {
  display: inherit !important;
}

.visible-on-mobile {
  display: none !important;
}

@media (max-width: 768px) {
  .svg-inline--fa {
    font-size: 1.5rem;
  }  

  .visible-on-laptop {
    display: none !important;
  }

  .visible-on-mobile {
    display: block !important;
  }
}