/* //src/styles/SharedLayout.css */
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.feature-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 0;
  background-color: #272424;
  width: 100vw;
}

.feature-content {
  background-color: #272424;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: var(--laptop-container-padding);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.big-header {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 4rem;
}

.page-content {
  max-width: 960px;
  margin: 0 auto;
  padding: var(--laptop-container-padding);
  font-size: 1.2rem;
  line-height: 2.2rem;
}

@media (max-width: 768px) {
  .feature-container {
    padding: var(--mobile-container-padding);
    padding: 3rem;
  }

  .feature-content {
    padding: 0;
    gap: 1.2rem;
  }

  .big-header {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .feature-text {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .page-content {
    font-size: 1rem;
    padding: var(--mobile-container-padding);
  }
}