
/* //src/styles/Menu.css */
.menu-container {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: expandMenu 0.2s ease-out forwards;
}

.menu-item.active {
  opacity: 1;
  transform: translateY(0);
}

.menu-item {
  width: 100%;
  padding: 2rem;
  text-align: center;
  font-size: 1.5rem;
}

@keyframes expandMenu {
  from {
    transform: scaleY(0.9);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}