/* //src/styles/Footer.css */
.footer {
  display: flex;
  flex-direction: row;
  font-size: small;
  width: 100%;
  padding: 4rem 8rem;
}

.footer-cell {
  display: flex;
  flex: 1;
  line-height: 1.25rem;
}

.footer-left {
  justify-content: flex-start;
}

.footer-right {
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem;
  }  
}