/* //src/styles/Header.css */
.header-container {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-color);
  padding: var(--laptop-container-padding);
  color: var(--secondary-font-color);
}

.header-container-row {
  display: flex;
  width: 100%;
  max-width: 960px;
  margin: auto;
}

.header-options {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

a.header {
  color: var(--primary-font-color);
  text-decoration: none;
}

a.header:hover {
  text-decoration: underline;
}

.laptop-header-menu {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width: 100%;
  justify-content: flex-end;
  padding-right: 3rem;
}

@media (max-width: 768px) {
  .header-container {
    padding: var(--mobile-container-padding);
  }

  .header-options {
    flex-grow: 0;
  }
}